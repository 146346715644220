import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreLoader from "components/preLoader/Preloader";
import { getCurrentUser } from "aws-amplify/auth";
import { RequireAuth } from "services/utils/RequireAuth";
import { Amplify } from "aws-amplify";
import config from "../aws-exports";

Amplify.configure(config);

const Backtest = lazy(() => import("../pages/Backtest"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
// const Settings = lazy(() => import("../pages/Settings"));
const Pricing = lazy(() => import("../pages/Pricing"));
const User = lazy(() => import("../pages/User"));
const Bot = lazy(() => import("../pages/Bot"));
const Keys = lazy(() => import("../pages/Keys"));
const Login = lazy(() => import("../pages/Login"));

const Config = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      try {
        const user = await getCurrentUser();
        setIsAuthenticated(!!user);
      } catch (error) {
        setIsAuthenticated(false);
        console.error("Error checking authentication:", error);
      } finally {
        setLoading(false);
      }
    }
    checkAuth();
  }, []);

  // While checking authentication, display a loading indicator
  if (loading) {
    return <PreLoader />;
  }
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated !== null ? (
                <RequireAuth redirectTo="/login" isLoggedIn={isAuthenticated}>
                  <Dashboard />
                </RequireAuth>
              ) : null
            }
          ></Route>
          <Route
            path="/backtest"
            element={
              isAuthenticated !== null ? (
                <RequireAuth redirectTo="/login" isLoggedIn={isAuthenticated}>
                  <Backtest />
                </RequireAuth>
              ) : null
            }
          />
          <Route
            path="/pricing"
            element={
              isAuthenticated !== null ? (
                <RequireAuth redirectTo="/login" isLoggedIn={isAuthenticated}>
                  <Pricing />
                </RequireAuth>
              ) : null
            }
          />
          <Route
            path="/settings/user"
            element={
              isAuthenticated !== null ? (
                <RequireAuth redirectTo="/login" isLoggedIn={isAuthenticated}>
                  <User />
                </RequireAuth>
              ) : null
            }
          />
          <Route
            path="/settings/bot"
            element={
              isAuthenticated !== null ? (
                <RequireAuth redirectTo="/login" isLoggedIn={isAuthenticated}>
                  <Bot />
                </RequireAuth>
              ) : null
            }
          />
          <Route
            path="/settings/keys"
            element={
              isAuthenticated !== null ? (
                <RequireAuth redirectTo="/login" isLoggedIn={isAuthenticated}>
                  <Keys />
                </RequireAuth>
              ) : null
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
