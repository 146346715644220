import React from "react";
import "./preloader.css";

const Preloader = () => {
  return (
    <div className="h-[100vh] w-full flex justify-center items-center">
      <div className="cm-spinner"></div>
    </div>
  );
};

export default Preloader;
